// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/@expo/webpack-config/node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("./css/normalize.css");
var ___HTML_LOADER_IMPORT_1___ = require("./css/style.css");
var ___HTML_LOADER_IMPORT_2___ = require("./main.js");
// Module
var ___HTML_LOADER_REPLACER_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACER_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACER_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<!doctype html> <html lang=en> <head> <meta charset=UTF-8> <title>The Tower of Hanoi</title> <link rel=stylesheet href=" + ___HTML_LOADER_REPLACER_0___ + "> <link href=\"https://fonts.googleapis.com/css?family=Press+Start+2P\" rel=stylesheet> <link rel=stylesheet href=" + ___HTML_LOADER_REPLACER_1___ + "> </head> <body> <div class=container> <div class=\"col c1\"></div> <div class=\"col c2\"> </div> <div class=\"col c3\"></div> </div> <footer> <a id=reset href=#>START</a> </footer> <script src=https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.0/jquery.min.js></script> <script src=" + ___HTML_LOADER_REPLACER_2___ + "></script> </body> </html> ";
// Exports
module.exports = code;